import React, { useState } from 'react';
import { FileLoadingModel } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import {
  addEpdFile,
  useAddSystemBoundaryFileMutation,
  useRemoveSystemBoundaryFileMutation,
  useUpdateSystemBoundaryMutation,
} from 'services/api/mutations';
import { useDictionaryValues, useEpdSystemBoundaryData } from 'services/api/queries';
import { FieldPanelFullWidth } from 'styles/v2/Styles.styled';

import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import { ImagesUpload } from '../images-upload/ImagesUpload';
import SmartLabel from '../labels/SmartLabel';
import SystemBoundaryGrid from './grids/system-boundary/SystemBoundaryGrid';
import GridPanel from './panels/expandable/GridPanel';

const SystemBoundaryTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
  onChangeEpd: any;
  canUpload: boolean;
  fileLoading?: FileLoadingModel[];
}> = ({ epdId, epdVersionId, validationState }) => {
  const dictionariesQuery = useDictionaryValues();
  const systemBoundaryDescriptionLookup =
    dictionariesQuery.data
      ?.filter((x) => x.dictionaryCode === EPD_DICTIONARY_CODES.SYSTEM_BOUNDARY_DESCRIPTION)
      .map((x) => {
        return {
          value: x.key,
          label: x.value,
        };
      }) || [];

  const systemBoundaryHasExcludedModulesLookup = [
    { value: true, label: 'Yes, there is the excluded module(s)' },
    { value: false, label: 'No, there is no excluded module(s)' },
  ];

  const errors = validationState?.errors;

  const boundaryData = useEpdSystemBoundaryData(epdVersionId).data;
  const updateSystemBoundaryMutation = useUpdateSystemBoundaryMutation(epdVersionId);

  const addSystemBoundaryFileMutation = useAddSystemBoundaryFileMutation(epdVersionId);
  const removeSystemBoundaryFileMutation = useRemoveSystemBoundaryFileMutation(epdVersionId);

  const onAddSystemBoundaryFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId, propertyName, file }, addSystemBoundaryFileMutation);
  };

  const onRemoveSystemBoundaryFile = async (fileId: string) => {
    removeSystemBoundaryFileMutation.mutate(fileId);
  };

  const onChangeEpdSystemBoundaryData = async (propertyName: string, val: any) => {
    updateSystemBoundaryMutation.mutate({
      epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  return (
    <FieldPanelFullWidth>
      <CompilerSelectControlledComponent
        label="Description of the system boundary"
        tooltip="Description of the EPD system boundary as one of the options (a-f) in PCR 2019:14"
        name="systemBoundaryDescription"
        options={systemBoundaryDescriptionLookup}
        value={systemBoundaryDescriptionLookup.find((x) => x.value == boundaryData?.description)}
        onChanged={onChangeEpdSystemBoundaryData}
        placeholder="Select..."
        isClearable={false}
        error={errors?.systemBoundaryDescription}
        required
      />

      <CompilerSelectControlledComponent
        label="Excluded modules"
        tooltip="Information on which life-cycle stages are not considered (if any), with a justification of the omission."
        name="hasExcludedModules"
        options={systemBoundaryHasExcludedModulesLookup}
        value={systemBoundaryHasExcludedModulesLookup.find((x) => x.value == boundaryData?.hasExcludedModules)}
        onChanged={onChangeEpdSystemBoundaryData}
        placeholder="Select..."
        isClearable={false}
        error={errors?.hasExcludedModules}
        required
      />
      {boundaryData?.hasExcludedModules && (
        <WizardTextAreaComponent
          label="Justification for the omission of modules"
          tooltip="Information on which life-cycle stages are not considered (if any), with a justification of the omission."
          value={boundaryData?.excludedModulesJustification}
          onChanged={onChangeEpdSystemBoundaryData}
          name="excludedModulesJustification"
          error={errors?.excludedModulesJustification}
          required
        />
      )}
      <GridPanel
        title="Declared modules"
        isReadOnly={false}
        error={errors?.declaredModulesErrors && Object.keys(errors?.declaredModulesErrors).length > 0 && 'Has errors'}
      >
        <SystemBoundaryGrid
          epdId={epdId}
          epdVersionId={epdVersionId}
          errors={{
            declaredModules: errors?.declaredModulesErrors?.declaredModules,
            declaredModulesGeography: errors?.declaredModulesErrors?.declaredModulesGeography,
            systemBoundary: errors?.declaredModulesErrors?.systemBoundary,
          }}
        />
      </GridPanel>
      <WizardTextAreaComponent
        label="Description of the process flow"
        tooltip="Collection of unit processes with elementary and product flows, performing one or more defined functions, and which models the life cycle of a product."
        value={boundaryData?.processFlowDescription}
        onChanged={onChangeEpdSystemBoundaryData}
        name="processFlowDescription"
      />

      <div>
        <SmartLabel label={'Process flow related images'} />
        <ImagesUpload
          name={'ProcessFlowImages'}
          images={boundaryData?.processFlowImages}
          onUpload={onAddSystemBoundaryFile}
          onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
          onRemove={onRemoveSystemBoundaryFile}
        />
      </div>
    </FieldPanelFullWidth>
  );
};

export default SystemBoundaryTab;
