import React from 'react';
import { Column, ColumnBodyOptions, ColumnEvent } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {
  useCreateEpdScrapInput,
  useCreateEpdShareOfTotalScrapInput,
  useDeleteEpdScrapInput,
  useUpdateEpdScrapInput,
  useUpdateEpdShareOfTotalScrapInput,
} from 'services/api/mutations';
import { useGetEpdScrapInputs, useGetEpdShareOfTotalScrapInput } from 'services/api/queries';
import { ScrapInputModel } from 'types/types';

import { cellTextEditor } from '../GridCellEditors';
import { validatedBodyTemplateWithPlaceholder } from '../GridService';
import { ColumnFooterInputNumber, DataTableStyled } from '../StyledGrid';
import DynamicGridHeader from '../DynamicGridHeader';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

const ContributionOfScrapInputsGrid: React.FunctionComponent<{ epdVersionId: string; errors?: any }> = ({
  epdVersionId,
  errors,
}) => {
  const isReadOnly = useIsReadOnlyMode();
  const { selectedRows, setSelectedRows, dataTableProps, checkboxColumnNode } =
    useGridRowSelectionViaCheckbox<ScrapInputModel>(isReadOnly);

  const shareOfTotalScrapInput = useGetEpdShareOfTotalScrapInput(epdVersionId!).data;
  const createShareOfTotalScrapInput = useCreateEpdShareOfTotalScrapInput();
  const updateShareOfTotalScrapInput = useUpdateEpdShareOfTotalScrapInput();

  const rows = useGetEpdScrapInputs(epdVersionId!).data;
  const createScrapInputMutation = useCreateEpdScrapInput();
  const updateScrapInputMutation = useUpdateEpdScrapInput();
  const deleteMutation = useDeleteEpdScrapInput();

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    createScrapInputMutation.mutate({ epdVersionId });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows.length) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows([]);
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateScrapInputMutation.mutate(rowData);
  };

  const mandatoryCellTemplate = (placeholder: string, value: ScrapInputModel, options: ColumnBodyOptions) =>
    validatedBodyTemplateWithPlaceholder(
      (value as any)?.[options?.field],
      placeholder,
      errors?.[`row[${options?.rowIndex}]`]?.[options?.field]
    );

  const mandatoryCellTemplateFormatted = (placeholder: string, value: ScrapInputModel, options: ColumnBodyOptions) =>
    validatedBodyTemplateWithPlaceholder(
      formatScrapInputValue((value as any)?.[options?.field]),
      placeholder,
      errors?.[`row[${options?.rowIndex}]`]?.[options?.field]
    );

  const isTableFilledIn = rows?.every((r) => r.name) && rows?.every((r) => r.value);

  const isDeleteDisabled = !selectedRows?.length || deleteMutation.isPending;

  const header = (
    <DynamicGridHeader
      hasValue={isTableFilledIn}
      caption="Scrap inputs data"
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      error={errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')}
      required
    />
  );

  const onFooterCellEditComplete = (e: any) => {
    const value = e.target.value?.replace(' %', '');
    if (!shareOfTotalScrapInput?.id) {
      createShareOfTotalScrapInput.mutate({ epdVersionId, sharePercent: value! ?? null });
      return;
    }
    updateShareOfTotalScrapInput.mutate({ ...shareOfTotalScrapInput, sharePercent: value! ?? null });
  };

  const footerNumberEditor = () => (
    <ColumnFooterInputNumber
      placeholder="Type value % "
      value={shareOfTotalScrapInput?.sharePercent}
      suffix=" %"
      onBlur={onFooterCellEditComplete}
      mode="decimal"
      step={0.01}
      maxFractionDigits={2}
      disabled={isReadOnly}
    />
  );

  const footerGroup = (
    <ColumnGroup>
      <Row style={{ borderTop: 'none' }}>
        <Column className="p-row-note" />
        <Column
          footer={<div>The share of the total scrap input that was assumed to come with an environmental burden</div>}
          className="p-row-note"
        />
        <Column footer={footerNumberEditor} colSpan={4} className={isReadOnly ? 'p-row-note' : undefined} />
      </Row>
    </ColumnGroup>
  );

  const formatScrapInputValue = (value: number | undefined) => (!!value ? `${value}, kg CO2 eq./kW` : null);

  return (
    <DataTableStyled
      dataKey="id"
      header={header}
      footerColumnGroup={footerGroup}
      value={rows}
      editMode="cell"
      rowClassName={rowClassName}
      showGridlines
      {...dataTableProps}
    >
      {checkboxColumnNode}
      <Column
        field="name"
        header="Material scrap name"
        editor={isReadOnly ? undefined : cellTextEditor}
        onCellEditComplete={onCellEditComplete}
        body={(data, options) => mandatoryCellTemplate('Type name', data, options)}
      />
      <Column
        field="value"
        header="Material scrap value"
        body={(data, options) => mandatoryCellTemplateFormatted('Type value, kg CO2 eq./kg', data, options)}
        editor={isReadOnly ? undefined : cellTextEditor}
        onCellEditComplete={onCellEditComplete}
      />
    </DataTableStyled>
  );
};

export default ContributionOfScrapInputsGrid;
