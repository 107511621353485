import React from 'react';
import { Column, ColumnBodyOptions, ColumnEditorOptions, ColumnEvent } from 'primereact/column';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import {
  useCreateEpdProductDangerMaterial,
  useDeleteProductDangerMaterial,
  useUpdateEpdProductDangerMaterial,
} from 'services/api/mutations';
import { useDangerMaterialsLookup, useEpdProductDangerMaterials } from 'services/api/queries';
import { EPDContentDeclarationProductType, ProductDangerMaterial } from 'types/types';

import { cellNumberEditor } from '../GridCellEditors';
import { validatedBodyTemplateWithPlaceholder } from '../GridService';
import { DataTableStyled } from '../StyledGrid';
import DynamicGridHeader from '../DynamicGridHeader';
import useIsReadOnlyMode from '../../hooks/useIsReadOnlyMode';
import useGridRowSelectionViaCheckbox from '../useGridRowSelectionViaCheckbox';

const DangerMaterialsGrid: React.FunctionComponent<{
  epdVersionId: string;
  errors?: any;
  queryKey: string;
  contentDeclarationType: EPDContentDeclarationProductType;
}> = ({ epdVersionId, errors, queryKey, contentDeclarationType }) => {
  const isReadOnly = useIsReadOnlyMode();
  const { selectedRows, setSelectedRows, dataTableProps, checkboxColumnNode } =
    useGridRowSelectionViaCheckbox<ProductDangerMaterial>(isReadOnly);

  const dangerMaterialsLookup = useDangerMaterialsLookup().data;

  const rows = useEpdProductDangerMaterials(epdVersionId, contentDeclarationType, queryKey).data;
  const createMutation = useCreateEpdProductDangerMaterial(queryKey);
  const updateMutation = useUpdateEpdProductDangerMaterial(epdVersionId, queryKey);
  const deleteMutation = useDeleteProductDangerMaterial(queryKey);

  const rowClassName = () => ({ 'p-disabled': isReadOnly });

  const handleAddRow = () => {
    createMutation.mutate({ epdVersionId, weightPercent: 0, declareContentType: contentDeclarationType });
  };

  const handleDeleteSelected = async () => {
    if (!selectedRows.length) return;
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id!)));
    setSelectedRows([]);
  };

  const isTableFilledIn = rows?.every((r) => r.name) && rows?.every((r) => r.weightPercent);

  const getIsTableFilledIn = () => {
    if (contentDeclarationType === EPDContentDeclarationProductType.Average) {
      return rows?.some(() => true) && isTableFilledIn;
    }
    return true;
  };

  const isDeleteDisabled = !selectedRows?.length || deleteMutation.isPending;
  const header = (
    <DynamicGridHeader
      caption="hazardous and toxic Substances"
      onAddRow={handleAddRow}
      onDeleteRows={handleDeleteSelected}
      deleteRowsDisabled={isDeleteDisabled}
      error={errors?.general || (errors && Object.keys(errors).length > 0 && 'Has errors')}
      hasValue={getIsTableFilledIn()}
      required
    />
  );

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field, originalEvent: event } = e;
    rowData[field] = newValue;
    updateMutation.mutate(rowData);
  };

  const selectEditor = (options: ColumnEditorOptions, lookupIdField: string) => {
    return (
      <Dropdown
        filter
        style={{ width: '100%', height: '38px', maxWidth: '20rem' }}
        value={options.rowData[lookupIdField] || options.value}
        optionLabel="name"
        optionValue="id"
        options={dangerMaterialsLookup}
        onChange={(e: DropdownChangeEvent) => {
          options.editorCallback?.(e.value);

          let { rowData, field } = options;
          rowData[lookupIdField || field] = e.value;
          updateMutation.mutate(rowData);
        }}
        itemTemplate={(option) => {
          return <div style={{ maxWidth: '40rem', wordWrap: 'break-word', fontSize: '12px' }}>{option.name}</div>;
        }}
      />
    );
  };

  const mandatoryCellTemplate = (placeholder: string, value: ProductDangerMaterial, options: ColumnBodyOptions) =>
    validatedBodyTemplateWithPlaceholder(
      (value as any)?.[options?.field],
      placeholder,
      errors?.[`row[${options?.rowIndex}]`]?.[options?.field]
    );

  return (
    <DataTableStyled
      header={header}
      value={rows}
      dataKey="id"
      tableStyle={{ minWidth: '49rem' }}
      showGridlines
      editMode="cell"
      rowClassName={rowClassName}
      {...dataTableProps}
    >
      {checkboxColumnNode}
      <Column
        field="name"
        header="Hazardous/Toxic substances from the candidate list"
        editor={isReadOnly ? undefined : (options) => selectEditor(options, 'dangerMaterialLookupId')}
        body={(data, options) => mandatoryCellTemplate('Select', data, options)}
      />
      <Column field="ecNo" header="EC No." style={{ minWidth: '8rem' }} />
      <Column field="casNo" header="Cas. No" style={{ minWidth: '8rem' }} />
      <Column
        field="weightPercent"
        header={'Weight per functional or declared unit %'}
        editor={isReadOnly ? undefined : cellNumberEditor}
        onCellEditComplete={onCellEditComplete}
        style={{ maxWidth: '10rem' }}
        body={(data, options) => mandatoryCellTemplate('Type here', data, options)}
      />
    </DataTableStyled>
  );
};

export default DangerMaterialsGrid;
