import TooltipErrorIcon from 'components/v2/icons/TooltipErrorIcon';
import { ColumnEvent } from 'primereact/column';
import { Placeholder } from './StyledGrid';

export const columnTotal = (columnName: keyof any, rows: any) => {
  const total = rows?.reduce((a: number, b: any) => {
    const value = b[columnName] as number | undefined;
    return a + (value ? value : 0);
  }, 0);

  const finalTotal = Number(total);

  const totalString = finalTotal.toString();
  const [integerPart, decimalPart] = totalString.split('.');

  if (!decimalPart) {
    return integerPart;
  }

  return integerPart + '.' + decimalPart.substring(0, 2);
};

export const onCellEdit = (e: ColumnEvent, updateMutation: any) => {
  let { rowData, newValue, field, originalEvent: event } = e;
  rowData[field] = newValue;
  updateMutation.mutate(rowData);
};

export const mandatoryBodyTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' /*, position: 'relative'*/ }}>
      <span>{value}</span>
      {(error || !value) && <TooltipErrorIcon content={error || 'Required'} />}
    </div>
  );
};

export const validatedBodyTemplateWithPlaceholder = (value: any, placeholder?: string, error?: any) => {
  if (value && !error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{value}</span>
      </div>
    );
  }

  if (value && error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{value}</span>
        <TooltipErrorIcon content={error || 'Required'} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Placeholder>{placeholder}</Placeholder>
      <TooltipErrorIcon content={error || 'Required'} />
    </div>
  );
};

export const cellTemplateWithPlaceholder = (value: any, placeholder?: string, error?: any) => {
  if (value) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
        <span>{value}</span>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <Placeholder>{placeholder}</Placeholder>
    </div>
  );
};

export const validatedCellTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
      <span>{value}</span>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );
};

export const cellTotalTemplate = (fieldName: string, rows: any, errors?: any) =>
  validatedCellTemplate(columnTotal(fieldName, rows), errors?.[`${fieldName}Total`]);

export const validatedCellPercentTemplate = (value: any, error?: any) => {
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <span>{value}%</span>
      {error && <TooltipErrorIcon content={error} />}
    </div>
  );
};

export const cellTotalPercentTemplate = (fieldName: string, rows: any, errors?: any) => {
  return validatedCellPercentTemplate(columnTotal(fieldName, rows), errors?.[`${fieldName}Total`]);
};
