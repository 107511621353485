import React, { useContext } from 'react';
import { CompanyContext } from 'contexts/CompanyContextProvider';
import { Subtype, VersionStateType } from 'services/EpdClient';
import EPDService from 'services/EpdService';
import { EPD_DICTIONARY_CODES } from 'services/api/constants';
import { createTag } from 'services/api/epdApi';
import {
  addEpdFile,
  useAddCoverPageFile,
  useChangeEPDDefinition,
  useRemoveCoverPageFile,
  useUpdateEpdAdditionalInformation,
} from 'services/api/mutations';
import {
  useDictionaryValues,
  useEPDDefinitionInformation,
  useEpdAdditionalInformation,
  useEpdCollectionsLookup,
  useEpdCoverPageInformation,
  useTagSource,
} from 'services/api/queries';
import styled from 'styled-components';
import { ExpandablePanelHeaderName, FieldPanelFullWidth } from 'styles/v2/Styles.styled';
import { Option } from 'types/types';
import { getOptionsFromDictionary } from 'util/utils';

import CompilerMultiSelect from '../epd-compiler-inputs/CompilerMultiSelect';
import CompilerSelectControlledComponent from '../epd-compiler-inputs/CompilerSelectControlledComponent';
import WizardCreatableSelectControlledComponent from '../epd-wizard-inputs/WizardCreatableSelectControlled';
import WizardHTMLEditor from '../epd-wizard-inputs/WizardHTMLEditor';
import WizardTextAreaComponent from '../epd-wizard-inputs/WizardTextArea';
import WizardTextInputComponent from '../epd-wizard-inputs/WizardTextInput';
import { ExpandableHelpBox } from '../help-boxes';
import TooltipHelpIcon from '../icons/TooltipHelpIcon';
import { ImagesUpload } from '../images-upload';
import SmartLabel from '../labels/SmartLabel';
import ToggleButton from './panels/toggleable/ToggleButton';
import { MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID, SECTOR_OF_MULTIPLE_PRODUCTS_ID, SINGLE_PRODUCT_ID } from './constants';
import VersionHistoryDialog from './dialogs/VersionHistoryDialog';

const DefinitionTab: React.FunctionComponent<{
  epdId: string;
  epdVersionId: string;
  validationState: any;
  versionState?: string;
}> = ({ epdId, epdVersionId, validationState, versionState }) => {
  const epdDefinitionInformation = useEPDDefinitionInformation(epdVersionId).data;
  const additionalInformation = useEpdAdditionalInformation(epdVersionId).data;
  const coverPageInformation = useEpdCoverPageInformation(epdVersionId).data;
  const addCoverPageFileMutation = useAddCoverPageFile(epdVersionId);
  const deleteCoverPageFileMutation = useRemoveCoverPageFile(epdVersionId);
  const tagSource = useTagSource().data;
  const updateEPDDefinitionProperty = useChangeEPDDefinition(epdVersionId);
  const updateMutation = useUpdateEpdAdditionalInformation(epdVersionId);

  const { companyId } = useContext(CompanyContext);
  const tagsValue = epdDefinitionInformation?.tags || [];

  const handleCreateTag = async (inputValue: string) => {
    const newOption = await createTag({ id: null, name: inputValue });
    onChangeEPDDefinitionProperty('Tags', [...tagsValue, { label: newOption.name, value: newOption.id } as Option]);
  };

  const onChangeEpdAdditionalInformation = async (propertyName: string, val: any) => {
    updateMutation.mutate({ epdId: epdId, versionId: epdVersionId, propertyName: propertyName, newValue: val });
  };

  const onAddCoverPageFile = async (propertyName: string, file: File) => {
    await addEpdFile({ epdId: epdId, propertyName, file }, addCoverPageFileMutation);
  };

  const onRemoveCoverPageFile = async (fileId: string) => {
    deleteCoverPageFileMutation.mutate(fileId);
  };

  const dictionariesQuery = useDictionaryValues();
  const epdCollectionsLookup = useEpdCollectionsLookup(companyId ?? '').data;

  const epdClassificationOptions = getOptionsFromDictionary(dictionariesQuery.data, EPD_DICTIONARY_CODES.EPD_CLASSIFICATION);

  const getSubTypeOptionLabel = (key: string) => {
    switch (key) {
      case Subtype.Specific:
        return 'Specific (single product)';
      case Subtype.Average:
        return 'Average (multiple products)';
      case Subtype.Representative:
        return 'Representative (Sector EPD)';
      case Subtype.Template:
        return 'Template (product not yet on the market)';
      default:
        return '';
    }
  };

  const getSubTypeOptionDisabled = (key: string) => {
    switch (key) {
      case Subtype.Representative:
        return epdDefinitionInformation?.epdClassification !== SECTOR_OF_MULTIPLE_PRODUCTS_ID;
      default:
        return false;
    }
  };

  const subtypeOptions: Option[] = (Object.keys(Subtype) as Array<keyof typeof Subtype>).map((key) => ({
    label: getSubTypeOptionLabel(key),
    value: Subtype[key].toString(),
    disabled: getSubTypeOptionDisabled(key),
  }));

  const getSubtypeValue = () => {
    if (epdDefinitionInformation?.subtype) {
      return subtypeOptions.find((x) => x.value == epdDefinitionInformation?.subtype?.toString() || '');
    }
    return {
      label: '',
      value: '',
    };
  };

  const productTypeOptions = getOptionsFromDictionary(dictionariesQuery.data, EPD_DICTIONARY_CODES.PRODUCT_TYPE);

  const conformityStandardsOptions = getOptionsFromDictionary(
    dictionariesQuery.data,
    EPD_DICTIONARY_CODES.CONFORMITY_STANDARDS
  );

  const selectedProductTypeOriginal = !isNaN(Number(epdDefinitionInformation?.productType))
    ? productTypeOptions.find((x) => x.value == epdDefinitionInformation?.productType)
    : productTypeOptions.find((x) => x.label == epdDefinitionInformation?.productType);

  const selectedProductType = selectedProductTypeOriginal
    ? ({
        value: selectedProductTypeOriginal?.value.toString(),
        label: selectedProductTypeOriginal?.label,
      } as Option)
    : null;

  const { declaredStandards } = epdDefinitionInformation || {};

  function getSelectedStandards(declaredStandards: string | undefined, conformityStandardsOptions: any[]) {
    if (!declaredStandards) return [];

    const standardsArray = declaredStandards?.split(',');

    return standardsArray?.map((x: any) => conformityStandardsOptions.find((y) => y.value == x));
  }

  const onChangeEPDDefinitionProperty = async (propertyName: string, val: any) => {
    updateEPDDefinitionProperty.mutate({
      epdId: epdId,
      versionId: epdVersionId,
      propertyName: propertyName,
      newValue: val,
    });
  };

  const isOptionDisabled = (option: any) => {
    return option.disabled;
  };

  const headerHelpBoxTemplate = (options: any, header: string, tooltip?: string, tooltipPosition?: any | undefined) => {
    return (
      <div className={options.className}>
        <ExpandablePanelHeaderName>
          <HelpBoldText>{header}</HelpBoldText>
          {tooltip && <TooltipHelpIcon content={tooltip} position={tooltipPosition} />}
        </ExpandablePanelHeaderName>
        <ToggleButton options={options} withMargins />
      </div>
    );
  };

  return (
    <>
      <FieldPanelFullWidth>
        <WizardTextInputComponent
          label="UUID of data set"
          name="uuid"
          tooltip="Automatically generated universally unique identifier of this data."
          disabled
          value={epdId}
        />
        <WizardTextInputComponent
          label="EPD Id"
          name="registrationNumber"
          tooltip="Automatically generated unique identifier for this EPD."
          disabled
          value={`EPD-IES-${epdDefinitionInformation?.registrationNumber}:${String(
            epdDefinitionInformation?.versionNumber ?? 1
          ).padStart(3, '0')}`}
        />
        <div>
          <WizardTextInputComponent
            label="EPD Version"
            name="versionNumber"
            tooltip="EPD Version"
            disabled
            value={`${String(epdDefinitionInformation?.versionNumber ?? 1).padStart(3, '0')}`}
          />
          <VersionHistoryDialog versions={epdDefinitionInformation?.epdVersions ?? []} />
        </div>
        {epdDefinitionInformation?.versionNumber !== 1 && (
          <WizardTextAreaComponent
            label="Version description"
            tooltip="Provide the description of a new version changes."
            tooltipPosition="right"
            placeholder="Type here"
            name="versionDescription"
            value={epdDefinitionInformation?.versionDescription}
            onChanged={onChangeEPDDefinitionProperty}
            error={validationState?.errors?.versionDescription}
            required
          />
        )}
        <WizardTextInputComponent
          label="EPD name"
          tooltip="Add the product name which will be stated as EPD name for both - in the EPD portal and in in the front cover page of the EPD document."
          tooltipPosition={'right'}
          required
          value={epdDefinitionInformation?.declarationName}
          onChanged={onChangeEPDDefinitionProperty}
          name="title"
          maxLength={150}
          error={validationState?.errors?.title}
        />
        <CompilerSelectControlledComponent
          label="Single or multiple products"
          tooltip="Choose an option based on whether the EPD covers a single product or multiple products, as well as how the results of the product group are presented. If one of the options on multiple products is selected list the products that are included in the EPD."
          name="epdClassification"
          options={epdClassificationOptions}
          value={epdClassificationOptions.find((x) => x.value == epdDefinitionInformation?.epdClassification)}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isClearable={false}
          required
          error={validationState?.errors?.epdClassification}
        />
        {epdDefinitionInformation?.epdClassification === SINGLE_PRODUCT_ID && (
          <WizardTextAreaComponent
            label="Justification for why this is representative"
            placeholder="Type here"
            name="representativeJustification"
            value={epdDefinitionInformation?.representativeJustification}
            onChanged={onChangeEPDDefinitionProperty}
            required
            error={validationState?.errors?.representativeJustification}
          />
        )}
        {epdDefinitionInformation?.epdClassification === MULTIPLE_PRODUCTS_BASED_ON_AVG_RESULTS_ID && (
          <WizardTextAreaComponent
            label="Description of how the averages have been determined"
            placeholder="Type here"
            name="determinationOfAverageDescription"
            value={epdDefinitionInformation?.determinationOfAverageDescription}
            onChanged={onChangeEPDDefinitionProperty}
            required
            error={validationState?.errors?.determinationOfAverageDescription}
          />
        )}
        {epdDefinitionInformation?.epdClassification === SECTOR_OF_MULTIPLE_PRODUCTS_ID && (
          <>
            <WizardTextAreaComponent
              label="A list of the contributing manufacturers that the Sector EPD covers"
              placeholder="Type here"
              name="listOfContributingManufacturers"
              value={epdDefinitionInformation?.listOfContributingManufacturers}
              onChanged={onChangeEPDDefinitionProperty}
              required
              error={validationState?.errors?.listOfContributingManufacturers}
            />
            <WizardTextAreaComponent
              label="How the selection of the sites/products has been done and the average has been determined"
              placeholder="Type here"
              name="sitesSelectionDescription"
              value={epdDefinitionInformation?.sitesSelectionDescription}
              onChanged={onChangeEPDDefinitionProperty}
              required
              error={validationState?.errors?.sitesSelectionDescription}
            />
            <WizardTextAreaComponent
              label="A statement that the document covers average values for an entire or partial product category"
              tooltip="Specifying the percentage of representativeness and, hence, the declared product is an average that is not available for purchase on the market and that the results are not representative for any specific manufacturer or its product."
              placeholder="Type here"
              name="documentCoverageStatement"
              value={epdDefinitionInformation?.documentCoverageStatement}
              onChanged={onChangeEPDDefinitionProperty}
              required
              error={validationState?.errors?.documentCoverageStatement}
            />
          </>
        )}
        <CompilerSelectControlledComponent
          label="Product to declare"
          tooltip="EPD can declare goods or services, choose between the two options."
          name="productType"
          options={productTypeOptions}
          required
          value={selectedProductType}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isClearable={false}
          error={validationState?.errors?.productToDeclare}
        />
        <CompilerSelectControlledComponent
          label="Statement of conformity with ISO"
          tooltip="Select the standards with which the EPD is compliant."
          name="declaredStandards"
          options={conformityStandardsOptions}
          required
          value={getSelectedStandards(declaredStandards, conformityStandardsOptions)}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          isMulti
          error={validationState?.errors?.declaredStandards}
        />
        <WizardCreatableSelectControlledComponent
          label="Keywords"
          tooltip="With the keywords you can help to find your your valid EPD documents in a quick way in our EPD library on environdec.com."
          components={{ DropdownIndicator: null }}
          value={tagsValue || []}
          name="tags"
          isMulti
          isClearable
          placeholder="Type here to add keyword..."
          options={tagSource?.map((tag) => {
            return { value: tag.id, label: tag.name };
          })}
          onCreateOption={handleCreateTag}
          onChanged={onChangeEPDDefinitionProperty}
        />
        <CompilerMultiSelect
          label="Add to collection"
          tooltip="With the EPD Collection pages you can organize and attractively present your valid EPD documents in a hierarchical, but flexible organizational structure to your stakeholders via our EPD Library."
          name="collections"
          options={epdCollectionsLookup}
          value={epdDefinitionInformation?.collections}
          onChanged={onChangeEPDDefinitionProperty}
          placeholder="Select..."
          disabled={!(epdCollectionsLookup && epdCollectionsLookup.length > 0)}
        />
        <div>
          <SmartLabel
            label={'Product image for cover page'}
            required
            hasValue={coverPageInformation?.productImages && coverPageInformation?.productImages.length > 0}
            tooltip="Product image which will present your product in the front cover page of the EPD document."
            error={validationState?.errors?.productImages}
          />
          <ImagesUpload
            name={'ProductImages'}
            single
            images={coverPageInformation?.productImages}
            onUpload={onAddCoverPageFile}
            onReorder={(imgIds: string[]) => EPDService.arrangeEpdFilesByModifyDate(imgIds)}
            onRemove={onRemoveCoverPageFile}
          />
        </div>
        <WizardTextAreaComponent
          label="Use advice for the EPD"
          placeholder="Type here"
          tooltip="Specification on how the EPD is to be used."
          name="useAdvice"
          value={epdDefinitionInformation?.useAdvice ?? ''}
          onChanged={onChangeEPDDefinitionProperty}
          required
          error={validationState?.errors?.useAdvice}
        />

        <CompilerSelectControlledComponent
          isClearable={false}
          required
          label="Subtype"
          tooltip="Indicates the type of data set regarding its representativeness."
          tooltipPosition="top | right"
          options={subtypeOptions}
          name="subtype"
          value={getSubtypeValue()}
          onChanged={onChangeEPDDefinitionProperty}
          error={validationState?.errors?.subtype}
          isOptionDisabled={isOptionDisabled}
        />
        <ExpandableHelpBox headerTemplate={(options) => headerHelpBoxTemplate(options, 'What is subtype?')} toggleable>
          <span>
            Select a subtype that indicates the representativeness of EPD. One of the following predefined data types shall
            be chosen:
            <li>Specific (single product).</li>
            <li>Average (multiple products).</li>
            <li>Representative (Sector EPD).</li>
            <li>Template (product not yet on the market).</li>
          </span>
        </ExpandableHelpBox>
      </FieldPanelFullWidth>

      <FieldPanelFullWidth>
        <div>
          <WizardHTMLEditor
            name="additional.references"
            value={additionalInformation?.references}
            label="References"
            tooltip="A list of references shall be included, including references to the GPI (including version number) and relevant standards and PCRs (registration number, name and version)."
            tooltipPosition="right"
            onChanged={onChangeEpdAdditionalInformation}
            stripPastedStyles={true}
          />
        </div>
      </FieldPanelFullWidth>
    </>
  );
};

const HelpBoldText = styled.span`
  font-weight: 600;
`;

export default DefinitionTab;
