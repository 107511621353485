import { useState } from 'react';
import { ColumnEvent } from 'primereact/column';
import {
  useCreateEpdDataQualityAssessmentItem,
  useCreateEpdDataQualityAssessmentSummary,
  useDeleteEpdDataQualityAssessmentItem,
  useUpdateEpdDataQualityAssessmentItem,
  useUpdateEpdDataQualityAssessmentSummary,
} from 'services/api/mutations';
import { useGetEpdDataQualityAssessment } from 'services/api/queries';
import styled from 'styled-components';
import { DataQualityAssessmentItemModel, DataQualityAssessmentSummaryModel } from 'types/types';
import { Dialog } from 'primereact/dialog';
import WizardTextAreaComponent from 'components/v2/epd-wizard-inputs/WizardTextArea';
import DataQualityAssessmentAndReferenceYearsGrid from '../../grids/data-quality-assessment-and-ref-years/DataQualityAssessmentAndReferenceYearsGrid';
import { isPositiveDecimal } from 'util/utils';
import { DataQualityAssessmentItemEnum } from 'services/EpdClient';

type TProps = {
  epdVersionId: string;
  errors?: any;
};

const DataQualityAssessmentAndReferenceYearsPanel = ({ epdVersionId, errors }: TProps) => {
  const [gridIsMaximized, setGridIsMaximized] = useState(false);

  const dataQualityAssessmentAndReferenceYears = useGetEpdDataQualityAssessment(epdVersionId).data;
  const createMutation = useCreateEpdDataQualityAssessmentItem(epdVersionId);
  const updateMutation = useUpdateEpdDataQualityAssessmentItem();
  const deleteMutation = useDeleteEpdDataQualityAssessmentItem(epdVersionId);

  const createDataQualityAssessmentSummaryMutation = useCreateEpdDataQualityAssessmentSummary();
  const updateDataQualityAssessmentSummaryMutation = useUpdateEpdDataQualityAssessmentSummary();

  const handleDataQualityAssessmentSummaryChanged = (_: any, val: any) => {
    if (dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentSummary === null) {
      createDataQualityAssessmentSummaryMutation.mutate({
        epdVersionId,
        dataQualityAssessmentSummary: val,
      } as DataQualityAssessmentSummaryModel);
      return;
    }

    updateDataQualityAssessmentSummaryMutation.mutate({
      epdVersionId,
      dataQualityAssessmentSummary: val,
    } as DataQualityAssessmentSummaryModel);
  };

  const handleAddRow = () => {
    createMutation.mutate();
  };

  const handleDeleteSelected = async (selectedRows: DataQualityAssessmentItemModel[]) => {
    await Promise.all(selectedRows.map((x) => deleteMutation.mutateAsync(x.id)));
  };

  const onCellEditComplete = (e: ColumnEvent) => {
    let { rowData, newValue, field } = e;
    let dataModified = false;

    switch (field) {
      case DataQualityAssessmentItemEnum.ReferenceYear:
        if (Array.isArray(newValue) && newValue.length === 2) {
          rowData.referenceYearStartDate = newValue[0];
          rowData.referenceYearEndDate = newValue[1];
          dataModified = true;
        }
        break;

      case DataQualityAssessmentItemEnum.ShareOfPrimaryData:
        if (isPositiveDecimal(newValue)) {
          rowData[field] = newValue;
          dataModified = true;
        }
        break;

      default:
        rowData[field] = newValue;
        dataModified = true;
        break;
    }

    if (dataModified) {
      updateMutation.mutate(rowData);
    }
  };

  return (
    <>
      {gridIsMaximized && (
        <MaximizedDialog
          header={'Data quality assessment and reference years'}
          visible={gridIsMaximized}
          maximizable={true}
          maximized={true}
          onHide={() => setGridIsMaximized(false)}
        >
          <DataQualityAssessmentAndReferenceYearsGrid
            dataQualityAssessmentItems={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentItems}
            onDeleteRows={handleDeleteSelected}
            onAddRow={handleAddRow}
            onCellEditComplete={onCellEditComplete}
            deleteButtonDisabled={deleteMutation.isPending}
            errors={errors}
          />
        </MaximizedDialog>
      )}
      <div style={{ marginBottom: 30 }}>
        <WizardTextAreaComponent
          label="Description of data quality assessment and reference years"
          placeholder="Type here"
          name="dataQualityAssessmentSummary"
          value={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentSummary}
          onChanged={handleDataQualityAssessmentSummaryChanged}
        />
      </div>
      <DataQualityAssessmentAndReferenceYearsGrid
        dataQualityAssessmentItems={dataQualityAssessmentAndReferenceYears?.dataQualityAssessmentItems}
        onDeleteRows={handleDeleteSelected}
        onAddRow={handleAddRow}
        onCellEditComplete={onCellEditComplete}
        deleteButtonDisabled={deleteMutation.isPending}
        onMaximize={() => setGridIsMaximized(true)}
        errors={errors}
      />
    </>
  );
};

const MaximizedDialog = styled(Dialog)`
  & .p-dialog-header-maximize {
    display: none;
  }
  & .p-dialog-content {
    padding: 10px 30px 30px 30px;
  }
`;

export default DataQualityAssessmentAndReferenceYearsPanel;
