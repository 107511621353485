import React from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import { Theme } from 'styles/theme';
import { Option } from 'types/types';

import SmartLabel, { TSmartLabelProps } from '../labels/SmartLabel';
import { ClearIndicator, DropdownIndicator } from './common-icons';
import { ActionState } from 'services/EpdClient';
import useIsReadOnlyMode from '../compiler/hooks/useIsReadOnlyMode';

type TProps = Partial<TSmartLabelProps> & {
  name: string;
  value?: any;
  options: any[] | undefined;
  isMulti?: boolean;
  style?: any;
  singleValueColor?: any;
  containerStyle?: any;
  onChanged: any;
  placeholder?: string;
  disabled?: boolean;
  isClearable?: boolean;
  hasError?: any;
  error?: any;
  state?: Record<string, ActionState>;
  isOptionDisabled?: (option: any, selectValue: any[]) => boolean;
  filterOption?: any;
};

const CompilerSelectControlledComponent: React.FunctionComponent<TProps> = ({
  label,
  options,
  tooltip,
  tooltipPosition,
  labelStyle,
  containerStyle,
  name,
  value,
  onChanged,
  disabled,
  isOptionDisabled,
  style,
  singleValueColor,
  placeholder,
  required,
  isMulti,
  isClearable = true,
  hasError,
  error,
  state,
  ...props
}) => {
  const theme = useTheme() as Theme;
  const customSelectStyles = {
    menu: (styles: any) => ({ ...styles }),
    control: (styles: any, state: any) => ({
      ...styles,
      borderRadius: '2px',
      color: theme.colors.primaryBlack,
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: 'normal',
      minHeight: '44px',
      borderColor: state.isFocused ? theme.colors.primaryOrange : hasError ? 'red' : theme.colors.elementsInputBorder,
      outline: state.isFocused ? `2px solid ${theme.colors.primaryOrange}` : '',
      '&:hover': {
        borderColor: theme.colors.primaryOrange,
      },
      '&[aria-disabled]': {
        backgroundColor: theme.colors.elementsFilledInput,
        color: theme.colors.primaryBlack70,
        borderWidth: 0,
      },
    }),
    singleValue: (styles: any) => ({ ...styles, color: singleValueColor ?? 'inherit' }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      fontSize: '100%',
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      '> div': {
        marginRight: 10,
        padding: 0,
      },
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#B4D0B64D',
      '> div': {
        color: theme.colors.primaryGreen,
      },
      '> div:last-child:hover': {
        color: theme.colors.primaryGreen,
        backgroundColor: '#ACC6BC',
      },
    }),
  };
  const isReadOnly = useIsReadOnlyMode() || disabled;

  return (
    <div
      style={{
        ...containerStyle,
        width: '100%',
        display: state?.[name] === ActionState.Hidden ? 'none' : 'flex',
        flexDirection: 'column',
      }}
    >
      {label && (
        <SmartLabel
          label={label}
          required={required}
          labelStyle={labelStyle}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          hasValue={
            isMulti ? value && value.length > 0 : value?.value !== undefined && value?.value !== null && value?.value !== ''
          }
          error={error}
        />
      )}
      <Select
        components={{ ClearIndicator, DropdownIndicator }}
        options={options}
        name={name}
        value={value}
        onChange={(e: any) => {
          e !== value && onChanged(name, isMulti ? e?.map((x: Option) => x.value) : e?.value);
        }}
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={isReadOnly}
        isOptionDisabled={isOptionDisabled as any}
        styles={customSelectStyles}
        {...props}
      />
    </div>
  );
};

export default CompilerSelectControlledComponent;
