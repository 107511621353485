import linkIcon from 'images/icons/svg/link-1.svg';
import React from 'react';
import styled from 'styled-components';
import { VersionHistoryModel } from 'types/types';
import { formatDate } from 'util/utils';

const VersionItem: React.FunctionComponent<{ data: VersionHistoryModel }> = ({ data }) => {
  return (
    <VersionContainer>
      <div>{data.fullIdentificationNumber}</div>
      <VersionDateAndDetailsContainer>
        {formatDate(data.versionDate)}
        <ShowDetailsIcon src={linkIcon} alt="link" />
      </VersionDateAndDetailsContainer>
    </VersionContainer>
  );
};

const VersionContainer = styled.div`
  background-color: #b4d0b64d;
  text-align: justify;
  padding: 14px 16px 14px 16px;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VersionDateAndDetailsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ShowDetailsIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
`;

export default VersionItem;
